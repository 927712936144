import {valueFormat} from "src/helper/valueFormat";

export const convertTips = (tipInfo, orderData) => {
  if(tipInfo?.kitchen && tipInfo?.driver) {
    const kitchenTip = Number(orderData?.charges?.tipInfo?.kitchen).toFixed(2);
    const driverTip = Number(orderData?.charges?.tipInfo?.driver).toFixed(2);
    const tips = Number(kitchenTip) + Number(driverTip);
    const fixedTip = Number(tips).toFixed(2);
    return valueFormat(`$ ${fixedTip}`);
  }else if(tipInfo?.kitchen && !tipInfo?.driver) {
    const kitchenTip = Number(orderData?.charges?.tipInfo?.kitchen).toFixed(2);
    const tips = Number(kitchenTip).toFixed(2);
    return valueFormat(`$ ${tips}`);
  }else if(!tipInfo?.kitchen && tipInfo?.driver) {
    const driverTip = Number(orderData?.charges?.tipInfo?.driver).toFixed(2);
    return valueFormat(`$ ${driverTip}`);
  }
}

export const convertTaxAndFee = (fee, tax) => {
  return valueFormat(
    `$ ${Number(
      (fee?.processingFee || 0) + (fee?.marketplaceFee || 0) + (fee?.ordersCoFee || 0) + (tax || 0)
    ).toFixed(2)}`
  )
}

export const convertKitchenTip = (kitchenTip) => {
  return valueFormat(
    `$ ${Number(kitchenTip).toFixed(2)}`
  )
}
