import React, { Fragment, useEffect, useState } from "react";
import "moment-timezone";
import "src/index.css";
import AuthService from "src/service/api";
import OrderHeader from "src/components/Order/OrderHeader";
import { useNavigate } from "react-router-dom";
import OrderProduct from "src/components/Order/OrderProduct";
import OrderCharges from "src/components/Order/OrderCharges";
import PaymentIcon from "src/images/payments.png";
import { groupByCategory } from "src/helper/groupByCategory";
import {valueFormat} from "src/helper/valueFormat";
import { formatTimeForReceipt } from "src/utils";
import OrderNote from "src/components/Header/OrderNote";

export const Order = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({});
  const [timeZoneId, setTimeZoneId] = useState("America/Los_Angeles");
  const productsData = groupByCategory(orderData?.categories);
  const getParams = () => {
    let parsedParams = (url) => {
      return url.split("/");
    };

    const params = parsedParams(window.location.pathname);
    const orderId = params[2];
    return {
      orderId
    }
  }


  useEffect(() => {
    const { orderId } = getParams();

    if (!orderId) {
      navigate("/404");
    }

    const fetchData = async () => {
      try {
        const response = await AuthService.getOrderData(orderId);
        if (response?.data?.data?.metadata?.business) {
          document.title = `Orders co | ${response?.data?.data?.metadata?.business?.companyName}`;
        } else {
          document.title = `Orders co`;
        }
        if (response) {
          setOrderData(response.data.data || {});
          setTimeZoneId(
            response?.data?.data?.metadata?.business?.timezone?.timeZoneId ||
            response?.data?.data?.metadata?.business?.timezone ||
            "America/Los_Angeles"
          );
        }
      } catch (e) {
        document.title = `Orders co`;
        setOrderData({});
        setTimeZoneId("America/Los_Angeles");
      }
    };
    fetchData().then();
  }, []);

  const convertPayment = (method) => {
    const PAYMENTS = {
      google_pay: "Google Pay",
      apple_pay: "Apple Pay",
      cash: "Cash",
      card: "External Card",
      reward: "Reward",
      coupon: "Coupon"
    };
    return PAYMENTS[method];
  };


  return (
    <>
      {orderData && Object.keys(orderData)?.length > 0 && (
        <div id="receipt">
          <OrderHeader orderData={orderData} timeZoneId={timeZoneId}/>
          {orderData?.instructions && (
            <OrderNote instructions={orderData?.instructions}/>
          )}
          <table id="table">
            <tbody>
            <tr>
              <td className="items">Items:</td>
            </tr>
            {Object.keys(productsData)?.length > 0 ? (
              Object.keys(productsData)?.map((keys, index) => {
                return (
                  <>
                    <Fragment key={keys}>
                      {productsData[keys]?.categoryName ? (
                        <>
                          {index !== 0 ? (
                            <div className={'separator'}></div>
                          ) : (
                            <></>
                          )}
                          <tr style={{ marginBottom: "3px" }}>
                            <td
                              style={{ marginLeft: "29px" }}
                              className="product product-name-longer"
                            >
                              {productsData[
                                keys
                                ]?.categoryName?.toUpperCase()}
                            </td>
                            <td
                              className="product  product-longer-price"
                              style={{
                                width: "100%",
                              }}
                            ></td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                    {productsData[keys]?.items?.map((products) =>
                      products?.items?.map((item) => {
                        return (
                          <Fragment key={item?.itemId}>
                            <OrderProduct product={item}/>
                          </Fragment>
                        )
                      })
                    )}
                  </>
                );
              })
            ) : (
              <></>
            )}
            </tbody>
          </table>
          <hr className="line-header"/>
          <table id="table">
            <tbody>
            <OrderCharges orderData={orderData}/>
            </tbody>
          </table>


          <>
            {orderData?.transactions?.length > 0 ? (
              <>
                <hr className="line-header"/>
                {orderData?.transactions?.map((transaction) => {
                  return (
                    <div key={transaction?._id} className="payment-container">
                      <div>
                        <img src={PaymentIcon} alt="payment icon"/>
                      </div>
                      <div style={{width: "100%"}}>
                        <div style={{width: "100%", display: "flex", justifyContent: 'space-between'}}>
                          <p className="payment-title">
                            Payments: by{" "}
                            {convertPayment(transaction?.tender?.method)}
                          </p>
                          <p className="payment-title">
                            {valueFormat(`$ ${Number(transaction?.amount?.amount || 0).toFixed(2)}`)}
                          </p>
                        </div>
                        {orderData?.tableNumber && (
                          <p className="cart-number-title" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                            Table:{" "}
                            {orderData?.tableNumber}
                          </p>
                        )}
                        {transaction?.createdAt && (
                          <p className="cart-number-title" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                            Time:{" "}
                            {formatTimeForReceipt(transaction?.createdAt)}
                          </p>
                        )}
                        {orderData?.employeeInfo && orderData?.employeeInfo?.employeeFirstName && (
                          <p className="cart-number-title" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                            Employee:{" "}
                            {orderData?.employeeInfo?.employeeFirstName} {`${orderData?.employeeInfo?.employeeLastName?.charAt(0)}.` || ''}
                          </p>
                        )}
                        {orderData?.payment &&
                        Object.keys(orderData?.payment)?.length &&
                        orderData?.payment?.payment_method_details?.card?.last4 ? (
                          <p className="cart-number">
                            Card no:{" "}
                            {`XXXX XXXX XXXX ${orderData?.payment?.payment_method_details?.card?.last4}`}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <>
                {orderData?.tableNumber || orderData?.employeeInfo ? (
                  <hr className="line-header"/>
                ) : (
                  <></>
                )}
                <div style={{paddingTop: '10px', paddingLeft: '32px'}}>
                  {orderData?.tableNumber && (
                    <p className="cart-number-title" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                      Table:{" "}
                      {orderData?.tableNumber}
                    </p>
                  )}
                  {orderData?.employeeInfo && orderData?.employeeInfo?.employeeFirstName && (
                    <p className="cart-number-title" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                      Employee:{" "}
                      {orderData?.employeeInfo?.employeeFirstName} {`${orderData?.employeeInfo?.employeeLastName?.charAt(0)}.` || ''}
                    </p>
                  )}
                </div>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
};
