import { memo } from "react";
import {convertDateByTimezone, getOrderTypeLabel} from "src/utils";

const OrderHeader = ({ orderData, timeZoneId }) => {

  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        {orderData?.displayNumber && (
          <p className="displayNumber">{orderData?.displayNumber}</p>
        )}
        {orderData?.metadata?.business && (
          <>
            <p
              className="companyName">{orderData?.metadata?.business?.title || orderData?.metadata?.business?.companyName}</p>
            <hr className="line-header"/>
          </>
        )}
        {orderData?.customerInfo?.firstName || orderData?.customerInfo?.name ? (
          <p className="user-message">
            {`Thanks for ordering, ${orderData?.customerInfo?.firstName || orderData?.customerInfo?.name}!`}
          </p>
        ) : (
          <p className="user-message">
            {`Thanks for ordering!`}
          </p>
        )}
        <p className="order-number">
          {orderData?.createdAt ? (
            <>
              {convertDateByTimezone(orderData?.createdAt, timeZoneId) - `Order #${orderData?.orderNumber}`}
            </>
          ) : (
            <>{`Order #${orderData?.orderNumber}`}</>
          )}
        </p>
        <hr className="line-header-long"/>
        <p className="orderType">
          {getOrderTypeLabel(orderData.orderType)}
        </p>
        <hr className="line-header-long"/>
      </div>
    </div>
  );
};
export default memo(OrderHeader);
