export const formatTimeForReceipt = (dateString) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Manually extracting the components from the date string
  const year = dateString.substring(0, 4);
  const monthIndex = parseInt(dateString.substring(5, 7), 10) - 1;
  const day = dateString.substring(8, 10);
  let hours = parseInt(dateString.substring(11, 13), 10);
  const minutes = dateString.substring(14, 16);
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const strTime = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  const month = months[monthIndex];

  return `${strTime} - ${month} ${parseInt(day, 10)}, ${year}`;
};

export function convertDateByTimezone(dateStr, timezone) {
  const [month, day, year] = dateStr.split('.').map(Number);

  const utcDate = new Date(Date.UTC(year, month - 1, day));
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return formatter.format(utcDate);
}

export const ORDER_TYPE_LABEL = {
  in_store: 'In Store',
  takeout: 'Takeout',
  phone_order_delivery: 'Phone Order/Delivery',
  phone_order_pickup: 'Phone Order/Pick Up',
};

export const DISCOUNT_TYPE_LABEL = {
  fixedAmount: 'fixedAmount',
  percentage: 'percentage',
};


export function getOrderTypeLabel(key) {
  return ORDER_TYPE_LABEL[key];
}