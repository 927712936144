import axiosInstance from "./axios";

const getOrderData = async (orderId ) => {
  try {
    return axiosInstance.get(`/back/order/details/${orderId}`)
  }catch (e) {
    throw new Error(e.message)
  }
}

const order = {
  getOrderData,
}

export default order
