import React, { memo } from 'react';

const OrderNote = ({ specialInstructions }) => {
  return (
    <>
      <div className={'orderNoteBlock'}>
        <strong className={'note'}>Note:</strong>
        &nbsp;&nbsp;<i>{specialInstructions}</i>
      </div>
      <hr className="line-header" />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps?.specialInstruction === nextProps?.specialInstruction;
};

export default memo(OrderNote, areEqual);