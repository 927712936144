import { Fragment, memo } from "react";
import { valueFormat } from "src/helper/valueFormat";
import {DISCOUNT_TYPE_LABEL} from "src/utils";

const OrderProduct = ({ product }) => {

  function getDiscountLabel(discount) {
    const label = discount?.couponName || discount?.couponTitle || discount?.discountCode;
    const discountValue = valueFormat(discount?.discountValue || 0).toFixed(2);

    if (!discount) return "";

    const isLabelValid = label && label.toLowerCase() !== "discount";

    if (DISCOUNT_TYPE_LABEL.fixedAmount === discount.discountType) {
      return isLabelValid
        ? `(${label}) $${discountValue} off`
        : `$${discountValue} off`;
    }

    if (DISCOUNT_TYPE_LABEL.percentage === discount.discountType) {
      return isLabelValid
        ? `(${label}) ${discount?.discountValue || 0}% off`
        : `${discount?.discountValue || 0}% off`;
    }

    return "";
  }

  return (
    <>
      <tr style={{ marginBottom: "3px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="product product-name-longer"
          >
            <p
              style={{
                width: "30px",
              }}
            >{`${product?.count}x `}</p>
            <span>{valueFormat(product?.name?.en)}</span>
          </div>
          <p className="product product-longer-price" style={{
            textDecoration: product?.discount ? 'line-through' : 'none',
          }}>
            {valueFormat(`$ ${Number(product?.price || 0).toFixed(2)}`)}
          </p>
        </div>
      </tr>
      <tr>
        {product?.couponCode || product?.discount > 0 ? (
          <>
            <p
              className="product product-name-longer"
              style={{marginLeft: '28px', fontWeight: 500}}
            >
              Discount {getDiscountLabel(product?.discountDetails)}
            </p>
            <p
              className="product product-longer-price"
              style={{ fontWeight: 500 }}
            >
              {valueFormat(`$ ${Number(product?.calculatedPrice || 0).toFixed(2)}`)}
            </p>
          </>
        ) : null}
      </tr>
      {product?.modifierGroup &&
        product?.modifierGroup?.length > 0 &&
        product?.modifierGroup.map((mod) => {
          return (
            <Fragment key={mod?._id}>
              <tr>
                <td className="modifier-main-name">{`${mod?.name?.en?.toUpperCase()}`}</td>
              </tr>
              {mod &&
                mod?.modifierOptions?.length > 0 &&
                mod?.modifierOptions.map((item) => {
                  return (
                    <tr key={item?._id}>
                      <td className="modifier-name">
                        <div>
                          {item?.count ? <b>{`${item?.count}x `}</b> : <></>}
                          <p>{`${item?.name?.en}`}</p>{" "}
                          {item?.freeCount ? <b>({item?.freeCount} free)</b> : ""}
                        </div>
                      </td>
                      <td className="modifier-name">
                        {item?.isFree
                          ? "-"
                          : valueFormat(`$ ${Number(item?.totalPrice).toFixed(2)}`)}
                      </td>
                    </tr>
                  );
                })}
            </Fragment>
          );
        })}
    </>
  );
};
export default memo(OrderProduct);
