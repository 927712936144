import React, { memo, useMemo } from "react";
import { valueFormat } from "src/helper/valueFormat";
import { checkDiscount } from "src/helper/checkDiscount";
import {
  convertKitchenTip,
  convertTaxAndFee,
  convertTips,
} from "src/helper/tips";
import { MESSAGE_VARIABLES } from "src/constants";

const OrderCharges = ({ orderData }) => {

  const checkTipSettings = useMemo(() => {
    const currentBusiness = orderData?.metadata?.business ? orderData?.metadata?.business : {};
    if (currentBusiness?.settings?.website?.tipDisplaySetting) {
      const { tipRestaurant, tipDriver } = currentBusiness?.settings?.website?.tipDisplaySetting;
      return {
        tipRestaurantEnabled: tipRestaurant?.showTipRestaurant || true,
        tipDriverEnabled: tipDriver?.showTipDriver || true
      };
    }
    return {
      tipRestaurantEnabled: true,
      tipDriverEnabled: true
    };
  },[orderData])

  function getDiscountLabel(discount) {
    const label = discount?.couponName || discount?.couponTitle || discount?.discountCode;
    return label && label.toLowerCase() !== "discount" ? ` (${label})` : ":";
  }
  console.log(orderData, 'orderData')
  return (
    <>
      <tr className="total" style={{fontSize: '16px'}}>
        <td>Subtotal:</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.subTotal).toFixed(2)}`)}
        </td>
      </tr>
      {(orderData?.charges?.couponAmount > 0 &&
        orderData?.charges?.couponAmount > 0) ||
      (orderData?.charges?.discount > 0) ? (
        <tr className="product">
          <td>
            Discount{getDiscountLabel(orderData?.charges?.discountDetails)}
          </td>
          <td style={{fontSize: "14px"}}>
            {valueFormat(
              `($${Number((orderData?.charges?.promotion?.couponAmount || 0) +
                (orderData?.charges?.discount || 0)).toFixed(
                2
              )})`
            )}
          </td>
        </tr>
      ) : <></>}
      {orderData.type === "delivery" && (
        <tr className="product">
          <td>Delivery Fee</td>
          <td style={{fontSize: '14px'}}>
            {valueFormat(
              `$ ${Number(orderData?.charges?.deliveryFee).toFixed(2)}`
            )}
          </td>
        </tr>
      )}
      {(checkTipSettings.tipDriverEnabled || checkTipSettings.tipRestaurantEnabled) && (
        <>
          {orderData.type !== "delivery" && !checkTipSettings.tipRestaurantEnabled ? (
            <></>
          ) : (
            <tr className="product">
              <td>Tips:</td>
              <td style={{fontSize: '14px'}}>{convertTips(orderData?.charges?.tipInfo, orderData)}</td>
            </tr>
          )}
          {(orderData.charges?.tipInfo?.kitchen ||
            orderData.charges?.tipInfo?.kitchen == 0) && checkTipSettings.tipRestaurantEnabled ? (
            <tr className="tip" style={{paddingLeft: '32px'}}>
              <td>Kitchen</td>
              <td style={{
                paddingRight: '200px',
                fontSize: '14px'
              }}>{convertKitchenTip(orderData.charges?.tipInfo?.kitchen)}</td>
            </tr>
          ) : (
            ""
          )}
          {orderData.type === "delivery" && checkTipSettings.tipDriverEnabled ? (
            <>
              {orderData.charges?.tipInfo?.driver ||
              orderData.charges?.tipInfo?.driver === 0 ? (
                <tr className="tip" style={{paddingLeft: '32px'}}>
                  <td>Driver</td>
                  <td style={{paddingRight: '200px', fontSize: '14px'}}>
                    {convertKitchenTip(orderData.charges?.tipInfo?.driver)}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
      <tr className="product">
        <td>Taxes and Fees</td>
        <td style={{fontSize: '14px'}}>
          {convertTaxAndFee(
            orderData?.charges?.servicingFee,
            orderData?.charges?.taxAmount
          )}
        </td>
      </tr>
      <tr className="product" style={{paddingLeft: "32px"}}>
        <td>Tax</td>
        <td style={{
          paddingRight: '200px',
          fontSize: '14px'
        }}>{valueFormat(`$ ${Number(orderData?.charges?.taxAmount || 0).toFixed(2)}`)}</td>
      </tr>
      {orderData?.charges?.servicingFee?.processingFee ||
      orderData?.charges?.servicingFee?.marketplaceFee ||
      orderData?.charges?.servicingFee?.ordersCoFee ? (
        <tr className="product" style={{paddingLeft: "32px"}}>
          <td>Processing Fee</td>
          <td style={{paddingRight: '200px', fontSize: '14px'}}>{valueFormat(
            `$ ${Number(
              (orderData?.charges?.servicingFee?.processingFee || 0) +
              (orderData?.charges?.servicingFee?.marketplaceFee || 0) +
              (orderData?.charges?.servicingFee?.ordersCoFee || 0)
            ).toFixed(2)}`
          )}</td>
        </tr>
      ) : (
        ""
      )}
      <hr className="line-header"/>
      <tr className="product-total">
        <td>Total:</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.total || 0).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.rewardAmount && orderData?.charges?.rewardAmount > 0 ? (
        <tr className="product-total">
          <td>{MESSAGE_VARIABLES.cashback}</td>
          <td style={{ fontSize: '14px' }}>{checkDiscount(orderData?.charges?.rewardAmount || 0)}</td>
        </tr>
      ) : (
        <></>
      )}
      <tr className="product-total">
        <td>Balance Due</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.balanceDue || 0).toFixed(2)}`)}
        </td>
      </tr>
    </>
  );
};
export default memo(OrderCharges);
